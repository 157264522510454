import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const homeservice=()=>{
    return(
        <div className="servicebackground">
            <h2>Services</h2>
            <div className="flexbox-container srvcback">
                <div className="dummy">

                </div>
                <div className="servicelist">
                    <Reveal effect="animated fadeInUp" delay>
                    <div className="srvdetails lg">
                        <div className="srvimage" style={{backgroundImage:"url(/images/electrical/electricalcover.jpg)",backgroundSize:"cover"}}></div>
                        <div className="srvbreif">
                            
                        <h6>Electrical</h6>
                            <div className="flexbox-container">
                            <p>We are committed to provide turnkey project solutions – supply & installation of electrical equipment like Cables, Relays, Switchgears, Transformers, & all kinds of Substation equipment, with our experienced manpower</p>
                            <Link  className="navi navtop" to="/electrical"><div className="arrow"><span><i className="fas fa-arrow-alt-circle-right"></i></span></div></Link>
                            </div>
                        </div>
                    </div>
                    <div className="srvdetails md">
                        <div className="srvimage" style={{backgroundImage:"url(/images/mep/MEPcover.jpg)",backgroundSize:"cover"}}></div>
                        <div className="srvbreif">
                            <h6>MEP</h6>
                            <div className="flexbox-container">
                            <p>ZABTECH specialises in MEP contracting, offering clients a unique, collaborative approach that is tailored to suit their needs, budgets and programme targets in the following areas.</p>
                            <Link  className="navi navtop" to="/mep"><div className="arrow"><span><i className="fas fa-arrow-alt-circle-right"></i></span></div></Link></div>
                        </div>
                    </div>
                    <div className="srvdetails sm">
                        <div className="srvimage" style={{backgroundImage:"url(/images/civil/Civilcover.jpg)",backgroundSize:"cover"}}></div>
                        <div className="srvbreif">
                            <h6>Civil</h6>
                            <div className="flexbox-container">
                            <p> Our in-house engineering resource of the company consists of highly qualified and experienced civil engineers, quantity surveyors, electrical, mechanical, safety and quality engineers.</p>
                            <Link  className="navi navtop" to="/civil"><div className="arrow"><span className="aranim"><i className="fas fa-arrow-alt-circle-right"></i></span></div></Link></div>
                        </div>
                    </div></Reveal>
                </div>

            </div>
        </div>
    )

}

export default homeservice;