import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const supply=()=>{
    return(
        <div className="servicedetail">
        <div className="flexbox-container">
            <div className="serviced">
                <h2 style={{color:"rgb(52,152,219)",fontWeight:"bold"}}>CIVIL</h2>
                <p>
                                    committed to the state of art construction engineering, management and safety technology. The principal activities to date comprise of Hotel /Residential and Commercial Buildings, Water and Sewerage Pipeline, Schools and Hospitals, Pumping Station, Control Building, Reservoir and Civil Work, Steel Structure, Fabrication and Erection, Instrumentation, Electrical and Mechanical Installations and Treatment Plant.</p>

                   <p> Our in-house engineering resource of the company consists of highly qualified and experienced civil engineers, quantity surveyors, electrical, mechanical, safety and quality engineers.</p>

                   <p> Our staff of project managers has an average of 20 years experience in Industrial and Commercial construction. These project managers supervise all phases of a project, assuring our clients an expedient and cost effective solution.</p>

                    <p>This broad range of personnel and equipment allows us to function as a full-service general contractor or as a specialty subcontractor.                </p>
                
                <div className="contractingservic">
                <Bounce bottom cascade>
                <ul>
                            <li><p>Painting</p>
                            <img src="../images/civil/civil.jpg" ></img>
                            </li>
                            <li><p>Tiling</p><img src="../images/civil/2.jpg" ></img></li>
                            <li><p>Parquet flooring</p><img src="../images/civil/3.jpg" ></img></li>
                            <li><p>False ceiling</p><img src="../images/civil/4.jpg"></img></li>
                            <li><p>Gypsum board</p><img src="../images/civil/5.jpg" ></img></li>
                            <li><p>Interlock</p><img src="../images/civil/6.jpg" ></img></li>
                            <li><p>Building maintenance</p><img src="../images/civil/Civilcover.jpg" ></img></li>
                </ul></Bounce>
                </div>
            </div>
        </div>
    </div>
        
    )

}

export default supply;