import React,{Component} from 'react';
import { Link } from 'react-router-dom';

class Headermob extends Component{
    state = {
        auth: false,
        slide: 0,  // How much should the Navbar slide up or down
        lastScrollY: 0,  // Keep track of current position in state
        display:'none',
      };
    
      componentWillMount() {
        // When this component mounts, begin listening for scroll changes
        window.addEventListener('scroll', this.handleScroll);
        this.setState({slide:'-100px'});

      }
    
      componentWillUnmount() {
        // If this component is unmounted, stop listening
        window.removeEventListener('scroll', this.handleScroll);
      }
    
      handleScroll = () => {
        const { lastScrollY } = this.state; 
        const currentScrollY = window.scrollY;
    
    
        if (currentScrollY > 100) {
          this.setState({ slide: '0px' });
        } else {
          this.setState({ slide: '-100px' });
        }
        this.setState({ lastScrollY: currentScrollY });
      };
    render(){
    return(
        <div className="mainhead" style={{
            transform: `translate(0, ${this.state.slide})`,
            transition: 'all 1000ms ease-in-out',
          }}>
            <div className="test">
                <nav className="navbar navbar-dark mb-4">

                    <a className="navbar-brand" href="#">ZabTec</a>


                    <button className="navbar-toggler first-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent21"
                        aria-controls="navbarSupportedContent20" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="animated-icon1"><span></span><span></span><span></span></div>
                    </button>


                    <div className="collapse navbar-collapse navhead" id="navbarSupportedContent21">


                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item ">
                            <Link className="nav-link moblink active" to="/">Home <span className="sr-only">(current)</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link moblink" to="/about">About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link moblink" to="/services">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link moblink" to="/clients">Clients</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link moblink" to="/contact">Contacts</Link>
                        </li>
                        </ul>


                    </div>
                </nav>

                

            </div>
        </div>
    )
    }
}

export default Headermob;