import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const homeservice=()=>{
    return(
        <div className="servicebackground">
            <h2>Electrical</h2>
            <div className="flexbox-container srvcback">
                <div className="dummy">

                </div>
                <div className="servicelist">
                    <Reveal effect="animated fadeInUp" delay>
                    <div className="srvdetails lg">
                        <div className="srvimage" style={{backgroundImage:"url(/images/electrical/contract.jpg)",backgroundSize:"cover"}}></div>
                        <div className="srvbreif">
                            
                        <h6>Contracting</h6>
                            <div className="flexbox-container">
                            <p>We are committed to provide turnkey project solutions – supply & installation of electrical equipment like Cables, Relays, Switchgears, Transformers, & all kinds of Substation equipment, with our experienced manpower</p>
                            <Link  className="navi navtop" to="/contracting"><div className="arrow"><span><i className="fas fa-arrow-alt-circle-right"></i></span></div></Link>
                            </div>
                        </div>
                    </div>
                    <div className="srvdetails md">
                        <div className="srvimage" style={{backgroundImage:"url(/images/electrical/supply.jpg)",backgroundSize:"cover"}}></div>
                        <div className="srvbreif">
                            <h6>Supply</h6>
                            <div className="flexbox-container">
                            <p>We are one of the leading provider of premium quality Electro-mechanical products, substation & protection equipment and tools for the developmental progress in Industrial or commercial, private or public sectors</p>
                            <Link  className="navi navtop" to="/supply"><div className="arrow"><span><i className="fas fa-arrow-alt-circle-right"></i></span></div></Link></div>
                        </div>
                    </div>
                    <div className="srvdetails sm">
                        <div className="srvimage" style={{backgroundImage:"url(/images/electrical/tandc.jpg)",backgroundSize:"cover"}}></div>
                        <div className="srvbreif">
                            <h6>Testing & Commissioning</h6>
                            <div className="flexbox-container">
                            <p> We are one of the leading provider of Testing & Commissioning services
                                All Types of LV/HV/MV/EHV Substations
                                Numerical Protection Relays for feeder, Cable, Transformer, Bus Bar, Generators and Motors.</p>
                            <Link  className="navi navtop" to="/maintenance"><div className="arrow"><span className="aranim"><i className="fas fa-arrow-alt-circle-right"></i></span></div></Link></div>
                        </div>
                    </div></Reveal>
                </div>

            </div>
        </div>
    )

}

export default homeservice;