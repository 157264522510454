import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Reveal from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';


const homeabt=()=>{
    return(
        <div className="homeabt">
            <div className="flexbox-container">
                <div className="breif">
                <h5>WELCOME</h5>
                <h2>Zehour Al Buston tech Cont LLC</h2>
                    <p>Zehour Al Bustan is fully owned and operated by a team of professionals with an aim to explore the potential of UAE market in
                    technical contracting industry. With our workforce including senior engineers, project managers, assistant managers, project
                    coordinators, technicians and helpers, ZAB Instills quality in every aspect of its work.
                    With a meticulous approach to project planning, ZAB ensures that all projects are completed on time…everytime!</p>
                    <p>When you hire ZAB your satisfaction is 100% Guaranteed! The work will be performed by a highly skilled, professional technician
                    who has been background checked, passed a drug screen and has received the highest level of industry training.</p>

                </div>
                <div className="breifimage">
                    <span></span>
                </div>
            </div>
            <div className="flexbox-container vmg">
                <Reveal effect="animated fadeInUp" delay>
                <div className="vision lg">
                    <span style={{fontSize:"80px",color:"#fc2003"}}><i className="fas fa-eye"></i></span>
                    <h4>VISION</h4>
                    <p>Quality Maintainence Services Leaders which you can trust</p>
                </div>
                <div className="mission md">
                    <span style={{fontSize:"80px",color:"#fc2003"}}><i className="fas fa-rocket"></i></span>
                    <h4>MISSION</h4>
                    <p>To become quality leaders in providing electromechanical and civil
                        works maintainence service in the GCC within the next Ten years
                    </p>
                </div>                
                <div className="goal sm">
                    <span style={{fontSize:"80px",color:"#fc2003"}}><i className="fas fa-crosshairs"></i></span>
                    <h4>GOALS</h4>
                    <p>Deliver maximum value to our clients. Increase customer
                        retention.Increase marketshare
                    </p>
                </div></Reveal>
            </div> 
         </div>
        
    )

}

export default homeabt;