import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const supply=()=>{
    return(
        <div className="servicedetail">
        <div className="flexbox-container">
            <div className="serviced">
                <h2 style={{color:"rgb(52,152,219)",fontWeight:"bold"}}>MEP</h2>
                <p>
                ZABTECH specialises in MEP contracting, offering clients a unique, collaborative approach that is tailored to suit their needs, budgets and programme targets in the following areas.
                </p>
                
                <div className="contractingservic">
                <Bounce bottom cascade>
                <ul>
                            <li><p>Heating, ventilation and air-conditioning (HVAC) including central and distributed chilled water systems, and associated plant equipment, microclimate control, packaged units and variable air volume (VAV).Heating, ventilation and air-conditioning (HVAC) including central and distributed chilled water systems, and associated plant equipment, microclimate control, packaged units and variable air volume (VAV).</p>
                            <img src="../images/mep/1.jpg" ></img>
                            </li>
                            <li><p>Plumbing, drainage and specialist systems including boilers, steam generators, water treatment, pumping stations and sewage treatment plants (STP’s).</p>
                            <img src="../images/mep/2.jpg" ></img>
                            </li>
                            <li><p>Electrical works including power and lighting installations, low voltage (LV) power distribution, generators, uninterrupted power supply systems, central battery systems and related controls.</p>
                            <img src="../images/mep/3.jpg" ></img>
                            </li>
                            <li><p>Extra-low voltage (ELV) systems and controls including building management systems (BMS), fire alarm, intruder alarm, access controls, closed circuit television (CCTV), internet protocol television (IPTV), surveillance, radio paging, intercom, public address (PA), audiovisual systems, conferencing systems, and all IP infrastructure based systems.</p>
                            <img src="../images/mep/4.jpg" ></img>
                            </li>
                            <li><p>Fire-fighting systems including sprinkler, deluge, clean agent FM200, active suppression systems, and other.</p>
                            <img src="../images/mep/5.jpg" ></img>
                            </li>
                            <li><p>Data and telecommunication systems including structured cabling, active component installations, GSM booster installations, IP-based integrated infrastructure for systems and automation.</p>
                            <img src="../images/mep/6.jpg" ></img>
                            </li>
                            <li><p>Specialist hotel electronics applications such as room management systems and automated billing systems</p>
                            <img src="../images/mep/7.jpg" ></img>
                            </li>
                </ul></Bounce>
                </div>
            </div>
        </div>
    </div>
        
    )

}

export default supply;