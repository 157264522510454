import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';
import Homeservice from "./homeservices" 

const servicedetails=()=>{
    return(
        <div className="servicedetail">
            <Homeservice></Homeservice>
         </div>
        
    )

}

export default servicedetails;