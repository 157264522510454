import React,{Component} from 'react';

class contactus extends Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        email: '',
        city: '',
        phone: '',
        message: ''
      }
    }
  
  render() {
   return(
     <div className="App">
     <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
      <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" className="form-control" placeholder="Enter your name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
      </div>
      <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email</label>
          <input type="email" className="form-control" placeholder="eg:'zabtech@gmai.com'" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
      </div>
      <div className="form-group">
          <label htmlFor="city">City</label>
          <input type="text" className="form-control" placeholder="eg:'Dubai'" value={this.state.city} onChange={this.onCityChange.bind(this)} />
      </div>
      <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input type="phone" className="form-control" placeholder="10 digit number" value={this.state.phone} onChange={this.onPhoneChange.bind(this)} />
      </div>
      <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea className="form-control" rows="5" placeholder="Lets Create history" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
      </form>
      </div>
   );
  }
  
    onNameChange(event) {
      this.setState({name: event.target.value})
    }
    onCityChange(event) {
        this.setState({city: event.target.value})
      }
    onPhoneChange(event) {
        this.setState({phone: event.target.value})
      }
  
    onEmailChange(event) {
      this.setState({email: event.target.value})
    }
  
    onMessageChange(event) {
      this.setState({message: event.target.value})
    }
    resetForm(){
    
        this.setState({name: '', email: '', city: '', phone:'',message: ''})
     }
  
      handleSubmit(e) {
        e.preventDefault();
        console.log(this.state);
        e.preventDefault();
    
        //fetch('http://localhost:3002/send',{
          fetch('http://emaildet.zabtech.ae/',{
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }).then(
            (response) => (response.json())
           ).then((response)=>{
          if (response.status === 'success'){
            alert("Message Sent."); 
            this.resetForm()
          }else if(response.status === 'fail'){
            alert("Message failed to send.")
          }
        })
      }
  }
  
  export default contactus;