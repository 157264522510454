import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Contactus from "./contactus"
import Map from "./map"

const contact=()=>{
    return(
        <div className="contactmain">
            <div className="form">
                {/* <Contact config={config}/> */}
                <Contactus/>
            </div>
            <div className="flexbox-container">
                <div className="contact">
                    <div className="contactimg">
                        <img></img>
                    </div>
                    <h5>Zehor Al Buston tech Cont LLC</h5>
                        <p>Mr. D. Vignesh<br></br>
                        Contracts Manager <br></br>
                        +971 56 279 9290<br></br>
                        Vignesh@zabtech.ae <br></br>
                        </p>
                </div>
                <div className="contact">
                    <div className="contactimg">
                        <img></img>
                    </div>
                    <h5>Zehor Al Buston tech Cont LLC</h5>
                        <p>Mr. Abdul Sathar<br></br>
                            Managing Director <br></br>
                            +971 50 200 7071<br></br>
                            Globaltlinks@yahoo.com<br></br>
                        </p>
                </div>

            </div>
            <div className="map">
                <Map className="mainmap"/>
            </div>

        </div>
    
    )

}

export default contact;