import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Corousel from './corousel'

const Fheader =()=>{
    return(
        <div className="Fheader" style={{ backgroundColor:`white` }}>
            <div className="flexbox-container">
                <Link to="/" className="logo1">
                    <span></span>
                </Link>
                <Link to="/" className="logo">
                    <span></span>
                </Link>
                <div className="navcontainer">
                <nav>
                    <Link className="navi" to="/">HOME</Link>
                    <Link className="navi" to="/about">ABOUT</Link>
                    <Link className="navi" to="/services">SERVICES</Link>
                    <Link className="navi" to="/clients">CLIENTS</Link>
                    <Link className="navi" to="/contact">CONTACTS</Link>
                    
                </nav>
                </div>
                </div>
            <div className="test">
                <nav className="navbar navbar-dark mb-1">

                    <Link to="/" className="logo1">
                    <span></span>
                    </Link>
                    <a className="navbar-brand" href="#">ZabTech</a>
                    


                    <button className="navbar-toggler first-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent20"
                        aria-controls="navbarSupportedContent20" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="animated-icon1"><span></span><span></span><span></span></div>
                    </button>


                    <div className="collapse navbar-collapse fixhead" id="navbarSupportedContent20">


                        <ul className="navbar-nav mr-auto">
                        <li className="nav-item ">
                            <Link className="nav-link fixlink active" to="/">Home <span className="sr-only">(current)</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link fixlink" to="/about">About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link fixlink" to="/services">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link fixlink" to="/clients">Clients</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link fixlink" to="/contact">Contacts</Link>
                        </li>
                        </ul>


                    </div>
                </nav>

                

            </div>

        </div>
    )
}

export default Fheader;