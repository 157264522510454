import React,{ Component } from 'react';

// COMPONENTS
import Corousel from './corousel'
import Homeabt from './homeabt'
import Homeservices from './homeservices'
import Homeclient from './homeclient'


class Home extends Component {
    constructor(props){
        super(props)

        this.state = {
            home:'',
            poll:[]
        }
    }

    render(){
        return(
            <div>
                <Corousel/>
                <div style={{paddingLeft:"5%",paddingRight:"5%"}}>
                <Homeabt/>
                <Homeservices/>
                <Homeclient/>
                </div>
            </div>
   
        )
    }
}


export default Home;