import React,{Component} from 'react';
import { Link } from 'react-router-dom';

class Header extends Component{
    state = {
        auth: false,
        slide: 0,  // How much should the Navbar slide up or down
        lastScrollY: 0,  // Keep track of current position in state
        display:'none',
      };
    
      componentWillMount() {
        // When this component mounts, begin listening for scroll changes
        window.addEventListener('scroll', this.handleScroll);
        this.setState({slide:'-100px'});

      }
    
      componentWillUnmount() {
        // If this component is unmounted, stop listening
        window.removeEventListener('scroll', this.handleScroll);
      }
    
      handleScroll = () => {
        const { lastScrollY } = this.state; 
        const currentScrollY = window.scrollY;
    
    
        if (currentScrollY > 100) {
          this.setState({ slide: '0px' });
        } else {
          this.setState({ slide: '-100px' });
        }
        this.setState({ lastScrollY: currentScrollY });
      };
    render(){
    return(
        <header style={{
            transform: `translate(0, ${this.state.slide})`,
            transition: 'all 1000ms ease-in-out',
          }}>
            <div className="flexbox-container">
                <Link to="/" className="logo1">
                    <span></span>
                </Link>
                <Link to="/" className="logo">
                    <span></span>
                </Link>
                <div className="navcontainer">
                <nav>
                    <Link className="navi" to="/">HOME</Link>
                    <Link className="navi" to="/about">ABOUT</Link>
                    <Link className="navi" to="/services">SERVICES</Link>
                    <Link className="navi" to="/clients">CLIENTS</Link>
                    <Link className="navi" to="/contact">CONTACTS</Link>
                </nav>
                
                </div>
            </div>
        </header>
    )
    }
}

export default Header;