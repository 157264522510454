import React from 'react';
import {Link} from 'react-router-dom'

const Footer = () => {
    return (
         <footer>
            <div className="flexbox-container">
                <Link to="/" className="logo1">
                    <span></span>
                </Link>
                <div className="logo">
                    <span></span>
                </div>
                <div className="contactus">
                    <h3>Zehour Al Buston</h3>
                    <h4>Po Box:7046</h4>
                    <p>Sharjah-United Arab Emirates<br></br>
                    Tel no: +971 6 535 2993<br></br>
                    Ph no: +971 56 823 7736<br></br>
                    For Enquiries: service@zabtech.ae<br/>
                    Contact: info@zabtech.ae</p>

                </div>
                <div className="footernav"> 
                <ul>
                    <li><Link className="navi" to="/">HOME</Link></li>
                    <li><Link className="navi" to="/about">ABOUT</Link></li>
                    <li><Link className="navi" to="/services">SERVICES</Link></li>
                    <li><Link className="navi" to="/clients">CLIENTS</Link></li>
                    <li><Link className="navi" to="/contact">CONTACTS</Link></li>
                </ul>
                </div>
                <div className="social">
                    <h4>Follow us on:</h4>
                    
                    <span className="icons">
                    <a href="https://www.linkedin.com/in/zabtech" target="_blank"><i className="fab fa-facebook fa-3x "></i></a>
                    </span>
                    <span className="icons">
                    <a href="https://www.linkedin.com/in/zabtech" target="_blank"><i className="fab fa-twitter fa-3x "></i></a>
                    </span>
                    <span className="icons">
                    <a href="https://www.linkedin.com/in/zabtech" target="_blank"><i className="fab fa-google-plus fa-3x "></i></a>
                    </span>
                    <span className="icons">
                    <a href="https://www.linkedin.com/in/zabtech" target="_blank"><i className="fab fa-linkedin fa-3x"></i></a>
                    </span>
                </div>
            </div>
            <div className="copy">
                <div className="copyrights"> 
                    <small >
                        &#169;  2020 Copyrights. All rights reserved by Zehur Al Bustan Tech Cont LLC
                    </small>
                </div>
            </div>
        </footer>
    )
}

export default Footer;