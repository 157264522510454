import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Rotate from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const civilservice=()=>{
    return(
<div className="servicedetail">
            <div className="flexbox-container">
                <div className="serviced">
                    <h2 style={{color:"rgb(52,152,219)",fontWeight:"bold"}}>CLIENTS</h2>
                    <p>Zehour Al Bustan is fully owned and operated by a team of professionals with an aim to explore the potential of UAE market in
                    technical contracting industry. With our workforce including senior engineers, project managers, assistant managers, project
                    coordinators, technicians and helpers, ZAB Instills quality in every aspect of its work.
                    With a meticulous approach to project planning, ZAB ensures that all projects are completed on time…everytime!</p>
                    <div className="contractingservic2">
                        <Rotate bottom right cascade>
                        <ul className="home-second-menu">
                            <li><p>AIR ARABIA CIVIL AVIATION SHARJAH </p><img className="submenu" src="../images/clients/1.jpg" ></img></li>
                            <li><p>NAEL GENERAL CONT. AL AIN </p><img className="submenu" src="../images/clients/2.jpg" ></img></li>
                            <li><p>PRIME GENERAL CONTRACTING </p><img className="submenu" src="../images/clients/3.jpg" ></img></li>
                            <li><p>AL AIN GENERAL</p><img className="submenu" src="../images/clients/4.jpg" ></img></li>
                            <li><p>GULF INDUSTRIAL SERVICE COMPANY </p><img className="submenu" src="../images/clients/5.jpg" ></img></li>
                            <li><p>AL AIN DISTRUBUTION COMPANY </p><img className="submenu"src="../images/clients/6.png" ></img></li>
                            <li><p>HYDRO POWER ENEGRY AND GENERAL CONSTRUCTION LLC </p><img className="submenu" src="../images/clients/7.jpg" ></img></li>
                            <li><p>DUBAI SPORTS CITY WADE ADAMS </p><img className="submenu" src="../images/clients/8.jpg" ></img></li>
                            {/* <li><p>VALUED CUSTOMERS AND EXPERIENCE RECORDS </p><img className="submenu" src="../images/clients/9.jpg" ></img></li> */}
                            <li><p>MADINA SUPER MARKETS BANIYAS </p><img className="submenu" src="../images/clients/10.png" ></img></li>
                            <li><p>MADINA HYPERMARKETS DUBAI </p><img className="submenu" src="../images/clients/11.png" ></img></li>
                            <li><p>PARCO REFRIGERATION AND KITCHEN EQUIPMENT </p><img className="submenu" src="../images/clients/12.png" ></img></li>
                            <li><p>CLEAN BLAST INTERNATIONAL DUBAI </p><img className="submenu" src="../images/clients/13.jpg" ></img></li>
                            <li><p>PALMS SPRINGS, VILLA PROJECT DUBAI </p><img className="submenu" src="../images/clients/14.png" ></img></li>
                            {/* <li><p>EMKE GENERAL TRADING DUBAI, ABU DHABI </p><img className="submenu" src="../images/clients/15.jpg" ></img></li> */}
                            <li><p>SHARJAH INDIAN ASSOCIATION(SCHOOL&OFFICE) </p><img className="submenu" src="../images/clients/16.jpg" ></img></li>
                            <li><p>SHARJAH PALACE RESTAURANT, SHARJAH, UA</p><img className="submenu" src="../images/clients/17.png" ></img></li>
                            <li><p>ADNOC DALMA ISLAND</p><img className="submenu" src="../images/clients/18.jpg" ></img></li>
                            <li><p>DANA BEACH HOTEL, SHARJAH, UAE </p><img className="submenu" src="../images/clients/19.png" ></img></li>
                            {/* <li><p>HBF MARINE SERVICES EAST RAMLA SUPERMARKET, D </p><img className="submenu" src="../images/clients/20.jpg" ></img></li>
                            <li><p>AHMED IBRAHIM AL HAI GENERAL CONT. ABU DHABI </p><img className="submenu" src="../images/clients/21.jpg" ></img></li> */}
                            <li><p>AL NAHDHA WOOD DECOR & FURNITURE CO LLC </p><img className="submenu" src="../images/clients/22.png" ></img></li>
                            {/* <li><p>AL SUWAHAT PLASTER & TILES CONT </p><img className="submenu" src="../images/clients/23.jpg" ></img></li> */}
                            <li><p>SPORTS CITY VICTORY HEIGHTS GOLF & RES DEV LLC </p><img className="submenu" src="../images/clients/24.png" ></img></li>
                            <li><p>DRAKE & SCULL INTERNATIONAL, DUBAI </p><img className="submenu" src="../images/clients/25.png" ></img></li>
                            <li><p>PALM JUMEIRAH VILLA PROJECT, DUBAI </p><img className="submenu" src="../images/clients/26.jpg" ></img></li>
                            <li><p>GOLDEN MEDALS CONT LLC </p><img className="submenu" src="../images/clients/27.png" ></img></li>
                            {/* <li><p>GENERAL MAINTENANCE ABU DHABI </p><img className="submenu" src="../images/clients/28.jpg" ></img></li> */}
                            <li><p>GLOBAL ENGNG SYSTEMS FZE SHARJAH </p><img className="submenu" src="../images/clients/29.jpg" ></img></li>
                            <li><p>LUMINUS ELECTRO MECHANICAL WORK LLS, DUBAI </p><img className="submenu" src="../images/clients/30.png" ></img></li>
                            <li><p>FAIRWAY CATERING, DUBAI, ABU DHABI </p><img className="submenu" src="../images/clients/31.png" ></img></li>
                            <li><p>RAINBOW CATERING, SHARJAH, UAE </p><img className="submenu" src="../images/clients/32.png" ></img></li>
                            <li><p>SANGEET RESTAURANT DUBAI, UAE </p><img className="submenu"  src="../images/clients/33.jpg" ></img></li>
                            <li><p>GENERAL CONSTRUCTION CO. DUBAI </p><img className="submenu" src="../images/clients/34.jpg" ></img></li>
                            <li><p>AFRICAN + EASTERN DUBAI(AMC) </p><img className="submenu" src="../images/clients/35.png" ></img></li>
                            <li><p>THUMBAY HOSPITAL, DUBAI, SHARJA</p><img className="submenu" src="../images/clients/36.jpg" ></img></li>
                        </ul></Rotate>
                        </div>
                    </div>
                </div>
            </div> 
    )

}

export default civilservice;