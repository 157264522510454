import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';


// COMPONENTS
import Header from "./header";
import Fheader from "./fixedheader";
import Footer from "./footer";
import Home from "./home";
import ServiceDetails from "./servicesdetails"
import Supply from "./supply"
import Tandc from "./tandc"
import Headermob from "./headermob"
import Aboutus from "./aboutus"
import Contact from "./contact"
import Contracting from "./contracting"
import Clients from "./clients"
import Electrical from "./electrical"
import Mepdetail from "./mepdetails"
import Civildetail from "./civildetail"


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
        <Fheader/>
        <Header/>
        <Headermob/>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/clients" component={Clients}></Route>
        <Route exact path="/services" component={ServiceDetails}></Route>
        <Route exact path="/supply" component={Supply}></Route>
        <Route exact path="/maintenance" component={Tandc}></Route>
        <Route exact path="/about" component={Aboutus}></Route>
        <Route exact path="/contact" component={Contact}></Route>
        <Route exact path="/contracting" component={Contracting}></Route>
        <Route exact path="/project" component={Home}></Route>
        <Route exact path="/electrical" component={Electrical}></Route>
        <Route exact path="/mep" component={Mepdetail}></Route>
        <Route exact path="/civil" component={Civildetail}></Route>
          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}


export default App;
