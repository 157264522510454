import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const supply=()=>{
    return(
        <div className="servicedetail">
        <div className="flexbox-container">
            <div className="serviced">
                <h2 style={{color:"rgb(52,152,219)",fontWeight:"bold"}}>SUPPLY</h2>
                <p>
                We are one of the leading provider of premium quality Electro-mechanical products, substation & protection equipment and tools for the developmental progress in Industrial or commercial, private or public sectors. We also have authorized dealership for various reputed products and also provide testing equipment on hire basis. We offer a range of products from all over the world, keeping the Customers delight as the primary focus of our attention.
                </p>
                
                <div className="contractingservic1">
                <Bounce bottom cascade>
                <ul>
                            <li>LV/HV/MV switchgear</li>
                            <li>SMBD/MDB</li>
                            <li>Relay and control panel</li>
                            <li>DMS/RTU</li>
                            <li>Protection relay and earth fault detector</li>
                            <li>Current transformer / Voltage transformer/ Meter</li>
                            <li>Power cables / LV cable</li>
                            <li>Battery and battery charges</li>
                            <li>Power transformer/ Auxiliary transformer</li>
                            <li>TRM, QRM, nKV package unit</li>
                            <li>Cable tray</li>
                </ul></Bounce>
                </div>
            </div>
        </div>
    </div>
        
    )

}

export default supply;