import React,{Component} from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
const mapStyles = {
    width: '100%',
    height: '600px',
    position:'relative',
  };
class MapContainer extends Component{
    render(){
    return(
        <Map
                    google={this.props.google}
                    zoom={8}
                    style={mapStyles}
                    initialCenter={{ lat: 25.131219, lng: 55.881436}}
                    >
                    <Marker position={{ lat: 25.131219, lng: 55.881436}} />
                </Map>
    );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyC6v5-2uaq_wusHDktM9ILcqIrlPtnZgEk&sensor'
  })(MapContainer);