import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const tandc=()=>{
    return(
<div className="servicedetail">
            <div className="flexbox-container">
                <div className="serviced">
                    <h2 style={{color:"rgb(52,152,219)",fontWeight:"bold"}}>TESTING & COMMISSIONING</h2>
                    <p>
                    We are one of the leading provider of premium quality TESTING & COMMISSIONING, All Types of LV/HV/MV/EHV Substations
Numerical Protection Relays for feeder, Cable, Transformer, Bus Bar, Generators and Motors. We also have authorized dealership for various reputed products and also provide testing equipment on hire basis. We offer a range of products from all over the world, keeping the Customers delight as the primary focus of our attention.
                    </p>
                    <div className="contractingservic1">
                        <Bounce bottom cascade>
                    <ul>
                        <li>  All Types of LV/HV/MV/EHV Substations</li>
                        <li>  Numerical Protection Relays for feeder, Cable, Transformer, Bus Bar, Generators and Motors</li>
                        <li>  Installation of Auxiliary</li>
                        <li>  Power Transformers up to 500MVA</li>
                        <li>  Battery & Battery Chargers</li>
                        <li>  System studies/ RLA Studies</li>
                        <li>  Fault Analysing / Trouble shooting</li>
                        <li>  Sweep Frequency Response Analysis</li>
                        <li>  Tan Delta & capacitance measurements</li>
                        <li>  Relay Coordination Studies</li>
                        <li>  Earth Grid design / Soil resistivity / Step & Touch Potential Test</li>
                        <li>  LV/HV/MV/EHV Substation design</li>
                        <li>  Maintenance Testing on Switchgear and Protection Relays</li>
                        <li>  Thermal Imaging</li>
                        <li>  Partial Discharge studies</li>
                    </ul></Bounce></div>
                    </div>
                </div>
            </div> 
    )

}

export default tandc;