import React,{Component} from 'react';
import { Link } from 'react-router-dom';


const aboutus=()=>{
    return(
        <div className="homeabt">
        <div className="flexbox-container">
                <div className="breif">
                <h5>WELCOME</h5>
                <h2>Zehour Al Buston tech Cont LLC</h2>
                    <p>Zehour Al Bustan is fully owned and operated by a team of professionals with an aim to explore the potential of UAE market in
                    technical contracting industry. With our workforce including senior engineers, project managers, assistant managers, project
                    coordinators, technicians and helpers, ZAB Instills quality in every aspect of its work.
                    With a meticulous approach to project planning, ZAB ensures that all projects are completed on time…everytime!</p>
                    <p>When you hire ZAB your satisfaction is 100% Guaranteed! The work will be performed by a highly skilled, professional technician
                    who has been background checked, passed a drug screen and has received the highest level of industry training.</p>
                    <p>Since its beginning in 2005, ZAB had taken all measures to place themselves in the market as a complete service provider  for most of technical needs. We are flexible because our work is driven by what the client needs, apart from technical  contracting service, we also provide services for gypsum partitions, painting, plumbing, mason works and electrical works.
                    Supported by well equipped workshop, machinery, vehicles and a team of highly professional staff we are ready to 
                    to take up  any kind of projects, no matter how big it is.</p>
                </div>
                <div className="breifimage">
                    <span></span>
                </div>
            </div>
            </div>
        
    )

}

export default aboutus;