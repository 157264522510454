import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const homeclient=()=>{
    return(
        <div className="clientmain">
            <h2>Our Nobelest Clients !!</h2>
            <div className="flexbox-container">
                <div className="clienta">
                <Bounce bottom cascade>
                <ul>
                            <li>AIR ARABIA CIVIL AVIATION SHARJAH </li>
                            <li>NAEL GENERAL CONT. AL AIN </li>
                            <li>PRIME GENERAL CONTRACTING </li>
                            <li>AL AIN GENERAL</li>
                            <li>GULF INDUSTRIAL SERVICE COMPANY </li>
                            <li>AL AIN DISTRUBUTION COMPANY </li>
                            <li>HYDRO POWER ENEGRY AND GENERAL CONSTRUCTION LLC </li>
                            <li>DUBAI SPORTS CITY WADE ADAMS </li>
                            <li>VALUED CUSTOMERS AND EXPERIENCE RECORDS </li>
                            <li>MADINA SUPER MARKETS BANIYAS </li>
                            <li>MADINA HYPERMARKETS DUBAI </li>
                            <li>PARCO REFRIGERATION AND KITCHEN EQUIPMENT </li>
                            <li>CLEAN BLAST INTERNATIONAL DUBAI </li>
                            <li>PALMS SPRINGS, VILLA PROJECT DUBAI </li>
                            <li>EMKE GENERAL TRADING DUBAI, ABU DHABI </li>
                            <li>SHARJAH INDIAN ASSOCIATION(SCHOOL&OFFICE) </li>
                            <li>SHARJAH PALACE ESTAURANT, SHARJAH, UA</li>
                            <li>ADNOC DALMA ISLAND</li></ul></Bounce>
                </div>
                <div className="clientb">
                <Bounce bottom cascade>
                            <ul><li>DANA BEACH HOTEL, SHARJAH, UAE </li>
                            <li>HBF MARINE SERVICES EAST RAMLA SUPERMARKET, D </li>
                            <li>AHMED IBRAHIM AL HAI GENERAL CONT. ABU DHABI </li>
                            <li>AL NAHDA WOOD DECOR & FURNITURE CO LLC </li>
                            <li>AL SUWAHAT PLASTER & TILES CONT </li>
                            <li>SPORTS CITY VICTORY HEIGHTS GOLF & RES DEV LLC </li>
                            <li>DRAKE & SCULL INTERNATIONAL, DUBAI </li>
                            <li>PALM JUMEIRAH VILLA PROJECT, DUBAI </li>
                            <li>GOLDEN MEDALS CONT LLC </li>
                            <li>GENERAL MAINTENANCE ABU DHABI </li>
                            <li>GLOBAL ENGNG SYSTEMS FZE SHARJAH </li>
                            <li>LUMINUS ELECTRO MECHANICAL WORK LLS, DUBAI </li>
                            <li>FAIRWAY CATERING, DUBAI, ABU DHABI </li>
                            <li>RAINBOW CATERING, SHARJAH, UAE </li>
                            <li>SANGEET RESTAURANT DUBAI, UAE </li>
                            <li>GENERAL CONSTRUCTION CO. DUBAI </li>
                            <li>AFRICAN + EASTERN DUBAI(AMC) </li>
                            <li>THUMBAY HOSPITAL, DUBAI, SHARJA</li>
                        </ul></Bounce>
                </div>

            </div>
        </div>
    )

}

export default homeclient;