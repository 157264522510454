import React,{Component} from 'react';
import { Link } from 'react-router-dom';


const corousel=()=>{
    return(
        <div>
        <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="../images/crane1.jpg" className="d-block w-100" alt="..." ></img>
      <div className="carousel-caption d-none d-md-block">
        <h5>VISION</h5>
        <p>Quality Maintainence Services Leaders which you can trust</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="../images/covers/cover_2.jpg" className="d-block w-100" alt="..."></img>
      <div className="carousel-caption d-none d-md-block">
        <h5>MISSION</h5>
        <p>To become quality leaders in providing electromechanical and civil works maintainence service in the GCC within the next Ten years</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="../images/covers/cover_3.jpg" className="d-block w-100" alt="..." ></img>
      <div className="carousel-caption d-none d-md-block">
        <h5>GOALS</h5>
        <p>Deliver maximum value to our clients. Increase customer retention.Increase marketshare</p>
      </div>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
</div>
    )

}

export default corousel;