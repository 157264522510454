import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import Bounce from 'react-reveal'; // REACT REVEAL
import 'animate.css/animate.css';

const contracting=()=>{
    return(
        <div className="servicedetail">
            <div className="flexbox-container">
                <div className="serviced">
                    <h2 style={{color:"rgb(52,152,219)",fontWeight:"bold"}}>CONTRACTING</h2>
                    <p>
                    We are committed to provide turnkey project solutions – supply & installation of electrical equipment like Cables, Relays, Switchgears, Transformers, & all kinds of Substation equipment, with our experienced manpower. We also provide specialized services in Jointing & termination, Modification & rectifications works, Approval & liaison works etc, and have a very satisfying record in all arena involving electrical contracting works.
                    </p>
                    <div className="contractingservic1">
                        <Bounce bottom cascade>
                    <ul >
                        <li>  Cable laying
                       
                        </li>
                        <li>       Cable jointing works and termination
                        </li>
                        <li>       Installation of Auxiliary</li>
                        <li>       Installation of substation earth grid</li>
                        <li>        Fibber optic cable laying</li>
                        <li>       Installation of TRM QRM and package unit</li>
                        <li>       Installation of cable tray</li>
                        <li>       Repairing of cable joint</li>
                        <li>       Streetlight pole erection</li>
                        <li>   Fencing work</li>
                        <li>   Primary station Electrification work</li>
                        <li>   Back filling</li>
                        <li>   Glanding and termination HV testing</li>
                        <li>   Package unit</li>
                    </ul></Bounce></div>
                    </div>
                </div>
            </div>
        
    )

}

export default contracting;